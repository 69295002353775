<template>
    <v-container class="w-container-edit-cespiti-admin v-overflow editCespitiAdmin"
        style="padding-top: 59px;background-color: rgb(255 255 255 / 50%);">

        <v-row>


            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px;z-index:3">

                <div class="mainTitle" style="
                background-color: white;
                color: black;
                font-weight: bold;
                padding: 4px;
                font-size: 10px;
                min-width:100vw;
                border-color:#f5cf2e">{{ titolo }}</div>

            </v-col>

        </v-row>


        <v-form v-model="valid" ref="form" lazy-validation>



            <v-row>

                <v-col cols="12" md="12" xs="12">

                    <div id="cont_companyid" style="cursor:pointer">
                        <ion-item id="itemIdAssetType"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-8px;margin-bottom:18px">

                            <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                position="floating">Tipo Cespiti </ion-label>

                            <ion-select id="idassettype" name="idassettype" class='idassettype' cancel-Text="Chiudi"
                                done-Text="" :value="cespiti.idasset_type" interface="action-sheet">

                                <ion-select-option v-for="item in tipo_cespiti" :key="item.idassettype"
                                    :value='item.idassettype'> {{ item.Description }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>

            <v-row v-if="visNoleggio">

                <v-col cols="12" md="12" xs="12">

                    <v-switch v-model="cespiti.noleggio" label="Noleggio" false-value="N" true-value="S"
                        hide-details></v-switch>

                </v-col>


            </v-row>


            <v-row v-if="cespiti.noleggio == 'S'">

                <v-col cols="12" md="12" xs="12">

                    <v-text-field v-model="cespiti.noleggio_da" id="noleggio_da" label="Noleggiato Da" maxlength="50">
                    </v-text-field>

                </v-col>


            </v-row>


            <v-row v-if="visDescrizione">

                <v-col cols="12" md="12" xs="12">

                    <v-text-field v-model="cespiti.description" id="description" label="Descrizione" maxlength="50">
                    </v-text-field>

                </v-col>


            </v-row>



            <v-row v-if="visNMatricola">

                <v-col cols="12" md="12" xs="12">

                    <v-text-field v-model="cespiti.serial_number" id="n_matricola" label="N. Matricola" maxlength="50">
                    </v-text-field>

                </v-col>


            </v-row>

            <v-row v-if="visOP">

                <v-col cols="12" md="12" xs="12">

                    <v-text-field v-model="cespiti.op_system" id="op_system" label="Sistema Operativo" maxlength="50">
                    </v-text-field>

                </v-col>


            </v-row>

            <v-row v-if="visSw">

                <v-col cols="12" md="12" xs="12">

                    <v-textarea v-model="cespiti.software" id="software" label="Software">
                    </v-textarea>

                </v-col>


            </v-row>

            <v-row v-if="visMemNB">

                <v-col cols="12" md="12" xs="12">


                    <v-text-field v-model="cespiti.notebook_memoria" id="notebook_memoria" label="Memoria" maxlength="50">
                    </v-text-field>


                </v-col>


            </v-row>

            <v-row v-if="visDiscoNB">

                <v-col cols="12" md="12" xs="12">

                    <v-text-field v-model="cespiti.notebook_disco" id="notebook_disco" label="Disco" maxlength="50">
                    </v-text-field>

                </v-col>


            </v-row>



            <v-row v-if="visNSim">

                <v-col cols="12" md="12" xs="12">

                    <v-text-field v-model="cespiti.num_sim" id="num_sim" label="Numero Sim" maxlength="20">
                    </v-text-field>

                </v-col>


            </v-row>

            <v-row v-if="visTelNum">

                <v-col cols="12" md="12" xs="12">

                    <v-text-field v-model="cespiti.tel_num" id="tel_num" label="Numero Telefono" maxlength="20">
                    </v-text-field>

                </v-col>


            </v-row>


            <v-row v-if="visTarga">

                <v-col cols="12" md="12" xs="12">

                    <v-text-field v-model="cespiti.targa" id="numtarga_sim" label="Targa" maxlength="20">
                    </v-text-field>

                </v-col>


            </v-row>

            <v-row v-if="visVeicolo">

                <v-col cols="12" md="12" xs="12">

                    <v-text-field v-model="cespiti.veicolo" id="veicolo" label="Veicolo" maxlength="50">
                    </v-text-field>

                </v-col>


            </v-row>



            <v-row v-if="visTipoDisco">

                <v-col cols="12" md="12" xs="12">

                    <div id="cont_companyid" style="cursor:pointer">
                        <ion-item id="itemIdAssetType"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-8px;margin-bottom:18px">

                            <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                position="floating">Tipo </ion-label>

                            <ion-select id="tipo_disco" name="tipo_disco" class='tipo_disco' cancel-Text="Chiudi"
                                done-Text="" :value="cespiti.tipo_disco" interface="action-sheet">

                                <ion-select-option v-for="item in tipi_dischi" :key="item.id" :value='item.id'> {{
                                    item.value
                                }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>


            <v-row v-if="visPollici">

                <v-col cols="12" md="12" xs="12">

                    <v-text-field v-model="cespiti.pollici" id="pollici" label="Pollici" maxlength="10">
                    </v-text-field>

                </v-col>


            </v-row>







        </v-form>


        <div class="contpopupfidoant">

            <template>
                <modal name="popupFidoAnt" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Fido Anticipi</label>

                            <div id="d_fido_ant_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="fidoAnt" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '\u00a0€',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0',
                                                modifyValueOnWheel: false,
                                            }" ref="fldFidoAnt" id="fldFidoAnt"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-fido-ant" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" />

                                <v-img alt="" class="btn-confirm-fido-ant" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="32" max-height="32" style="cursor:pointer"
                                    transition="scale-transition" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contpopupfidocassa">

            <template>
                <modal name="popupFidoCassa" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Fido Cassa</label>

                            <div id="d_fido_cassa" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="fidoCassa" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '\u00a0€',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0',
                                                modifyValueOnWheel: false,
                                            }" ref="fldFidoCassa" id="fldFidoCassa"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-fido-cassa" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" />

                                <v-img alt="" class="btn-confirm-fido-cassa" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="32" max-height="32" style="cursor:pointer"
                                    transition="scale-transition" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>



    </v-container>
</template>

<script>
import apiusers from "../utils/users/apiusers";
import apicespiti from "../utils/cespiti/apicespiti";


import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';

import {
    bus
} from "../main";

import router from ".././router";

import Vue from "vue";
import VModal from 'vue-js-modal';


export default ({
    mounted() {

        this.$root.$children[0].checkLogout();

        Vue.use(VModal, {
            dialog: true
        });

        /*eslint-disable no-undef*/

        var pointerVue = this;


        this.setupButtons();

        if (this.$route.params.id == 0) {

            this.titolo = "Aggiungi Cespiti";

            setTimeout(() => {


                this.syncInitInsert();


            }, 100);

        }

        if (this.$route.params.id > 0) {

            this.titolo = "Modifica Cespiti";

            setTimeout(() => {


                this.syncAsset();


            }, 100);

        }



        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":

                    this.validate();

                    break;

                case "btn_back":
                    //alert("btn_add_x");
                    router.push({
                        path: "/cespitiNonAssegnatiAdmin"
                    });
                    break;

                default:
                    break;
            }
        });

        $(document).ready(function () {



            $('body').on('click', '.btn-confirm-fido-ant', function (event) {
                console.log(event);

                pointerVue.banca.fido_anticipi = pointerVue.fidoAnt;

                pointerVue.$root.$children[0].setFieldActive("#d_fido_ant");

                pointerVue.$modal.hide('popupFidoAnt');

            });


            $('body').on('click', '.btn-cancel-fido-ant', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupFidoAnt');

            });

            $('body').on('click', '#d_fido_ant', function (event) {
                console.log(event);


                pointerVue.$modal.show('popupFidoAnt');

            });


            $('body').on('click', '#cont_idresource', function (event) {
                console.log(event);

                pointerVue.sheetRisorse = true;

            });



            $('body').on('click', '#cont_idmailgroup', function (event) {
                console.log(event);

                pointerVue.sheetGruppi = true;

            });




            $('body').on('click', '#d_importo_user', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupImporto');

            });


            $('body').on('click', '.btn-confirm-massimale', function (event) {
                console.log(event);

                pointerVue.utente.max_easyer = pointerVue.valoreMassimale;

                pointerVue.$root.$children[0].setFieldActive("#d_importo_user");

                pointerVue.$modal.hide('popupImporto');

            });

            $('body').on('click', '.btn-cancel-massimale', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImporto');

            });






            $('body').on('click', '.btn-confirm-fido-cassa', function (event) {
                console.log(event);

                pointerVue.banca.fido_cassa = pointerVue.fidoCassa;

                pointerVue.$root.$children[0].setFieldActive("#d_fido_cassa");

                pointerVue.$modal.hide('popupFidoCassa');

            });


            $('body').on('click', '.btn-cancel-fido-cassa', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupFidoCassa');

            });

            $('body').on('click', '#d_fido_cassa', function (event) {
                console.log(event);


                pointerVue.$modal.show('popupFidoCassa');

            });


            setTimeout(() => {

                var oggDrop = $('.dropify').dropify({
                    messages: {
                        default: 'Drag',
                        replace: /* s */ '',
                        remove: 'Rimuovi',
                        error: 'File troppo grande'
                    }
                });

                $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
                $(".dropify-render").append("<img>");

                //console.log(oggDrop);

                oggDrop.on('dropify.afterClear', function (event, element) {

                    console.log(event, element);

                    pointerVue.attachRemove = true;

                });

            }, 100);


            $(".idassettype").on("ionChange", function (opt) {
                console.log(opt);

                if ((this.value == 4) || (this.value == 1) || (this.value == 2) || (this.value == 3)) {

                    pointerVue.visNoleggio = true;

                    pointerVue.visDescrizione = true;
                    pointerVue.visNMatricola = true;
                    pointerVue.visOP = true;
                    pointerVue.visSw = true;


                    pointerVue.visMemNB = true;
                    pointerVue.visDiscoNB = true;


                    pointerVue.visNSim = false;
                    pointerVue.visTelNum = false;

                    pointerVue.visTarga = false;
                    pointerVue.visVeicolo = false;

                    pointerVue.visTipoDisco = false;

                    pointerVue.visPollici = false;

                }


                if ((this.value == 5) || (this.value == 6)) {

                    pointerVue.visNoleggio = true;


                    pointerVue.visDescrizione = false;
                    pointerVue.visNMatricola = false;
                    pointerVue.visOP = false;
                    pointerVue.visSw = false;

                    pointerVue.visMemNB = false;
                    pointerVue.visDiscoNB = false;


                    pointerVue.visNSim = true;
                    pointerVue.visTelNum = true;

                    pointerVue.visTarga = false;
                    pointerVue.visVeicolo = false;

                    pointerVue.visTipoDisco = false;

                    pointerVue.visPollici = false;

                }

                if (this.value == 7) {

                    pointerVue.visNoleggio = true;


                    pointerVue.visDescrizione = false;
                    pointerVue.visNMatricola = false;
                    pointerVue.visOP = false;
                    pointerVue.visSw = false;

                    pointerVue.visMemNB = false;
                    pointerVue.visDiscoNB = false;


                    pointerVue.visNSim = false;
                    pointerVue.visTelNum = false;

                    pointerVue.visTarga = true;
                    pointerVue.visVeicolo = true;

                    pointerVue.visTipoDisco = false;

                    pointerVue.visPollici = false;

                }

                if (this.value == 9) {

                    pointerVue.visNoleggio = true;


                    pointerVue.visDescrizione = true;
                    pointerVue.visNMatricola = false;
                    pointerVue.visOP = false;
                    pointerVue.visSw = false;

                    pointerVue.visMemNB = false;
                    pointerVue.visDiscoNB = false;


                    pointerVue.visNSim = false;
                    pointerVue.visTelNum = false;

                    pointerVue.visTarga = false;
                    pointerVue.visVeicolo = false;

                    pointerVue.visTipoDisco = false;

                    pointerVue.visPollici = false;

                }


                if (this.value == 10) {

                    pointerVue.visNoleggio = true;


                    pointerVue.visDescrizione = true;
                    pointerVue.visNMatricola = false;
                    pointerVue.visOP = false;
                    pointerVue.visSw = false;

                    pointerVue.visMemNB = false;
                    pointerVue.visDiscoNB = false;


                    pointerVue.visNSim = false;
                    pointerVue.visTelNum = false;

                    pointerVue.visTarga = false;
                    pointerVue.visVeicolo = false;

                    pointerVue.visTipoDisco = true;

                    pointerVue.visPollici = false;

                }


                if (this.value == 11) {

                    pointerVue.visNoleggio = true;


                    pointerVue.visDescrizione = true;
                    pointerVue.visNMatricola = false;
                    pointerVue.visOP = false;
                    pointerVue.visSw = false;

                    pointerVue.visMemNB = false;
                    pointerVue.visDiscoNB = false;


                    pointerVue.visNSim = false;
                    pointerVue.visTelNum = false;

                    pointerVue.visTarga = false;
                    pointerVue.visVeicolo = false;

                    pointerVue.visTipoDisco = false;

                    pointerVue.visPollici = true;



                }




            });




        });

    },

    components: {
        VueAutonumeric,
    },


    computed: {

        filteredAziende() {

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

        filteredGruppi() {

            return _.orderBy(this.gruppi.filter(item => {
                if (!this.searchGruppi) return this.gruppi;
                return (item.name.toLowerCase().includes(this.searchGruppi.toLowerCase()));
            }), 'headline')
        },

        filteredRisorse() {

            return _.orderBy(this.risorse.filter(item => {
                if (!this.search) return this.risorse;
                return (item.resource.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },


    },

    data: () => ({



        visNoleggio: false,

        risNomeSede: "",

        attachRemove: false,

        sheetRisorse: false,

        fidoAnt: null,
        fidoCassa: null,

        visDescrizione: false,
        visNMatricola: false,
        visOP: false,
        visSw: false,
        visTipoDisco: false,

        visPollici: false,

        visNSim: false,
        visTelNum: false,

        visTarga: false,
        visVeicolo: false,

        visMemNB: false,
        visDiscoNB: false,


        sheetGruppi: false,

        searchGruppi: "",

        tipo_cespiti: [],

        risorse: [],

        banca: {},

        cespiti: { noleggio: "N" },

        showPicker2: false,

        valoreMassimale: null,

        changePsw: false,

        titolo: "",
        currDipendenteObj: {},

        gruppi_checked: [],

        isCurrentUserSU: "N",

        aziende: [],
        prefissi: [],

        dipendenti: [],
        currAzienda: "",

        gruppi: [],

        selNuclei: null,

        tab: null,

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        utente: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        surnameRules: [
            (v) => !!v || "Cognome richiesto",
        ],

        aziendaRules: [
            (v) => !!v || "Azienda richiesta",
        ],

        usernameRules: [
            (v) => !!v || "Username richiesto",
        ],

        select: null,

        // items: [
        //     'Informazioni Generali', 'Gruppo', 'Autenticazione', 'QR Code'
        // ],

        items: [
            'Informazioni', 'Gruppo', 'Nota Spese'
        ],

        checkbox: false,

        tipo_qrcode: [{
            "id": "USR_PWD",
            "value": "Utente E Password"
        },
        {
            "id": "FCODE_PWD",
            "value": "Utente E Codice Fiscale"
        }
        ],

        tipi_dischi: [{
            "id": "RPM",
            "value": "RPM"
        },
        {
            "id": "SSD",
            "value": "SSD"
        }
        ]

    }),

    methods: {


        manageClickRisorse: async function (risorsa) {

            console.log("RIS SEL: ", risorsa);

            this.sheetRisorse = false;

            this.cespiti.idresource = risorsa.resourceid;

            //  this.syncOfficeRes(risorsa.resourceid);

        },




        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.banca.companyid = azienda.companyid;

        },

        manageClickGruppi: async function (gruppo) {

            console.log("GRP SEL: ", gruppo);

            this.sheetGruppi = false;

            this.banca.idmailgroup = gruppo.idmailgroup;

        },


        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Salva",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_save",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/icon_save_emt.png",
                    // class: "inverted",
                    title: "Salva",
                    width: 30

                },
                {
                    text: "Indietro",
                    icon: "mdi-arrow-left",
                    image: "https://app.emtool.eu/public/_lib/img/back-emt.png",
                    link: "/tipologiapagamento",
                    id: "btn_back",
                    disabled: false,
                    title: "Indietro",
                    width: 35

                },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {

            var that = this;


            var errore = "";

            if ($("#idassettype").val() == "") {
                errore = "Specificare il tipo";
            }


            if (errore == "") {

                if (that.cespiti.noleggio == "S") {

                    if ($("#noleggio_da").val() == "") {
                        errore = "Specificare il noleggiato da";
                    }

                }

            }



            if (errore == "") {

                if (that.visTarga) {

                    if ($("#numtarga_sim").val() == "") {
                        errore = "Specificare il numero della targa";
                    }

                }

            }



            if (errore == "") {

                if (that.visVeicolo) {

                    if ($("#veicolo").val() == "") {
                        errore = "Specificare il modello del veicolo";
                    }

                }

            }




            if (errore == "") {

                if (that.visNSim) {

                    if ($("#num_sim").val() == "") {
                        errore = "Specificare il numero della sim";
                    }

                }

            }

            if (errore == "") {

                if (that.visTelNum) {

                    if ($("#tel_num").val() == "") {
                        errore = "Specificare il numero di telefono";
                    }

                }

            }







            if (errore == "") {

                if (that.visDescrizione) {

                    if ($("#description").val() == "") {
                        errore = "Specificare la descrizione";
                    }

                }

            }



            if (errore == "") {

                if (that.visTipoDisco) {

                    if ($("#tipo_disco").val() == "") {
                        errore = "Specificare il tipo";
                    }

                }

            }


            if (errore == "") {

                if (that.visPollici) {

                    if ($("#pollici").val() == "") {
                        errore = "Specificare i pollici";
                    }

                }

            }



            if (errore == "") {

                if (that.visNMatricola) {

                    if ($("#n_matricola").val() == "") {
                        errore = "Specificare il numero di matricola";
                    }

                }

            }


            if (errore == "") {

                if (that.visOP) {

                    if ($("#op_system").val() == "") {
                        errore = "Specificare il sistema operativo";
                    }

                }

            }


            // if (errore == "") {

            //     if (that.visSw) {

            //         if ($("#software").val() == "") {
            //             errore = "Specificare il software";
            //         }

            //     }

            // }

            if (errore == "") {

                if (that.visMemNB) {

                    if ($("#notebook_memoria").val() == "") {
                        errore = "Specificare la memoria";
                    }

                }

            }

            if (errore == "") {

                if (that.visDiscoNB) {

                    if ($("#notebook_disco").val() == "") {
                        errore = "Specificare il disco";
                    }

                }

            }



            if (errore == "") {

                console.log("ok");

                that.saveData();

            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });


            }



        },

        saveData: async function () {



            var that = this;

            that.cespiti.idasset_type = $("#idassettype").val();
            that.cespiti.tipo_disco = $("#tipo_disco").val();

            console.log("CESPITI: ", that.cespiti);

            var v_token = window.$cookies.get("token");

            var response = null;

            if (that.$route.params.id == 0) {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                response = await apicespiti.insertCespitiNonAssegnato(
                    that.cespiti,
                    v_token,
                    undefined
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from insertCespitiNonAssegnato", res);

                    if (res.data.Result == "OK") {

                        that.$swal({
                            icon: "success",
                            text: "Dati salvati correttamente",
                            showConfirmButton: false,
                            timer: 2000
                        });

                        setTimeout(() => {

                            that.dialogMsg = false;
                            that.setupButtons();

                            router.push({
                                path: "/cespitiNonAssegnatiAdmin"
                            });

                        }, 200);

                    }
                    else {

                        that.$swal({
                            icon: "error",
                            text: res.data.ErrDetails,
                            showConfirmButton: false,
                            timer: 2000
                        });

                    }



                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );

            }


            if (that.$route.params.id > 0) {


                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                response = await apicespiti.updateCespitiNonAssegnato(
                    that.cespiti,
                    v_token,
                    undefined,
                    that.attachRemove,
                    that.$route.params.id
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from updateCespitiNonAssegnato", res);

                    if (res.data.Result == "OK") {


                        that.$swal({
                            icon: "success",
                            text: "Dati salvati correttamente",
                            showConfirmButton: false,
                            timer: 2000
                        });

                        setTimeout(() => {

                            that.dialogMsg = false;
                            that.setupButtons();

                            router.push({
                                path: "/cespitiNonAssegnatiAdmin"
                            });

                        }, 200);

                    }
                    else {

                        that.$swal({
                            icon: "error",
                            text: res.data.ErrDetails,
                            showConfirmButton: false,
                            timer: 2000
                        });


                    }



                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );

            }








        },

        syncUtente: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getUtente(
                that.$route.params.id,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getUtente", res);

                try {

                    this.aziende = res.data.aziende;
                    this.prefissi = res.data.prefix;
                    this.gruppi = res.data.models;
                    this.utente = res.data.curr_user;

                    this.utente.codice_fiscale = res.data.val_codice_fiscale;

                    if (res.data.curr_user.models != null) {

                        var grp_selected = res.data.curr_user.models.split(",");

                        console.log("GRP SEL: ", grp_selected);

                        if (grp_selected.length > 0) {

                            for (var i = 0; i < this.gruppi.length; i++) {

                                if (this.gruppi[i] !== undefined) {

                                    for (var x = 0; x < grp_selected.length; x++) {

                                        console.log("GRP SEL 2: ", grp_selected[x]);

                                        if (parseInt(this.gruppi[i].id) == parseInt(grp_selected[x])) {

                                            this.gruppi[i].checked = this.gruppi[i].id;

                                        }

                                    }

                                }

                            }

                        }


                    }




                    $("#itemAzienda").addClass("item-has-value");


                    console.log("USER INFO EASYER: ", res.data.info_easyer);


                    if (res.data.info_easyer != null) {

                        this.utente.enable_easyer = res.data.info_easyer.enable;

                        this.utente.max_easyer = res.data.info_easyer.max_value;

                        this.utente.datestart_easyer = res.data.info_easyer.period_from;

                        this.utente.dateend_easyer = res.data.info_easyer.period_to;


                    }






                    //console.log("ok");

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        syncAziende: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.createUtenteApi(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from syncAziende", res);

                try {

                    this.aziende = res.data.aziende;

                    this.prefissi = res.data.prefix;

                    this.gruppi = res.data.models;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },


        syncMailGroup: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apibanche.getGruppi(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from syncMailGroup", res);

                this.gruppi = res.data.invoice_mail_group;



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },


        syncInitInsert: async function () {


            var id_cmp_sel = window.$cookies.get("sel_filter_azienda_cespiti_admin");

            console.log("ID AZIENDA: ", id_cmp_sel);


            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicespiti.initInsert(
                v_token,
                id_cmp_sel
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initInsert", res);


                this.tipo_cespiti = res.data.asset_type;

                this.risorse = res.data.resource;



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },



        syncOfficeRes: async function (id_resource) {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicespiti.getOfficeRes(
                v_token,
                id_resource
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getOfficeRes", res);

                this.risNomeSede = res.data.DescOffice;



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },



        syncAsset: async function () {

            var that = this;

            var id_cmp_sel = window.$cookies.get("sel_filter_azienda_cespiti_admin");

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicespiti.initEdit(
                that.$route.params.id,
                v_token,
                id_cmp_sel
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initEdit", res);


                this.tipo_cespiti = res.data.asset_type;

                this.risorse = res.data.resource;

                this.cespiti = res.data.asset;

                this.cespiti.idasset_type = res.data.asset.idassettype;

                this.cespiti.tipo_disco = res.data.asset.tipo_disco;

                this.cespiti.pollici = res.data.asset.schermo_pollici;

                $("#itemIdAssetType").addClass("item-has-value");

                $("#itemRisorsa").addClass("item-has-value");



                if (res.data.asset.filename != null) {

                    $('.dropify-render').append("<i class='dropify-font-file'></i>");
                    $('.dropify-render').append("<span class='dropify-extension'>pdf</span>");

                    // dropify-font-file
                    $(".dropify-wrapper").addClass("has-preview");
                    $(".dropify-preview").show("slow");

                }






            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },




        syncBanca: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apibanche.getBanca(
                that.$route.params.id,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getBanca", res);

                this.aziende = res.data.aziende;

                this.gruppi = res.data.invoice_mail_group;

                this.banca = res.data.banca;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },



        syncPrefix: async function () {

            var that = this;

            //var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getPrefix().then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPrefix", res);

                try {

                    this.prefissi = res.data.tel_prefix;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.cl_importo {
    padding-left: 0px !important;
}


.itemAzienda {
    margin-left: 15px;
}

.itemRisorsa {
    margin-left: 15px;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetRisorse {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.itemGruppo {
    margin-left: 15px;
}

.headerSheetGruppi {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}





.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.w-container-edit-cespiti-admin {
    width: 500px;
    padding: 0px;
    overflow-x: hidden;
}

/* .w-container-users {
    width: 700px;
    padding: 0px;
} */

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.editCespitiAdmin .v-form {
    margin-top: 41px;
    padding-left: 10px;
    padding-right: 10px;
}

.editCespitiAdmin .v-input {
    font-size: 1.2em !important;
}

.editCespitiAdmin .v-select {
    font-size: 1.2em !important;
}

.editCespitiAdmin .v-label {
    font-size: 1em !important;
}

.v-tabs:not(.v-tabs--vertical) .v-tab {
    white-space: normal;
    font-size: 12px;
    padding: 0px;
}

@media screen and (max-width: 768px) {

    .w-container-edit-cespiti-admin {
        width: 100vw;
        padding: 0px;
    }

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

    .w-container-edit-cespiti-admin .dropify-wrapper {
        width: 353px !important;

    }

}

.theme--light.v-tabs>.v-tabs-bar,
.theme--light.v-tabs-items {
    background-color: transparent;
}
</style>
