<template>
    <v-container fluid fill-height class="text-center d-flex align-center justify-center"
        style="max-width: 300px; min-width: 300px">

        <div class="Absolute-Center">

        </div>

    </v-container>
</template>

<script>
import {
    bus
} from "../main";
import router from ".././router";
import $ from 'jquery';
//import Login from './login.vue'

export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];
        bus.$off("bottomIconClickEvent");
        this.$root.$children[0].showProgress = false;
    },

    mounted: async function () {

        window.$cookies.remove("a");
        window.$cookies.remove("b");
        window.$cookies.remove("token");
        window.$cookies.remove("system");
        window.$cookies.remove("token_system");
        window.$cookies.remove("username");
        window.$cookies.remove("user_id");
        window.$cookies.remove("is_ebadge");
        window.$cookies.remove("namesurname");
        window.$cookies.remove("is_pwd_changed_once");
        window.$cookies.remove("last_menu");
        window.$cookies.remove("is_invite_cespiti");
        window.$cookies.remove("companyid_rich_hol");
        window.$cookies.remove("resourceid_rich_hol");
        window.$cookies.remove("idrichesta_rich_hol");
        window.$cookies.remove("models");
        window.$cookies.remove("product_permissions");
        window.$cookies.remove("cid");
        window.$cookies.remove("sel_filter_azienda_storico_ferie");
        window.$cookies.remove("sel_filter_idrichiesta_storico_ferie");



        var that = this;

        console.log(this.$root.$children);
        console.log("PARAMS: ", this.$route.params.id);
        console.log("PARAMS LENGTH: ", this.$route.params.id.length);


        setTimeout(() => {

            if (that.$route.params && that.$route.params.id.length > 0) {


                console.log("STR INPUT: ", that.$route.params.id);

                var substr = that.$route.params.id.toString().substr(1, that.$route.params.id.toString().length - 2);

                //console.log("STR OK: ", substr);

                var str_decod = atob(substr);

                //console.log("STR_DECOD: ", str_decod);


                var elements = str_decod.split("|");

                console.log("ELEMENTS: ", elements);

                that.loginAction(elements[0], elements[1], elements[2]);



            }



            // if (that.$route.params && that.$route.params.id.length > 0)
            //     that.loginAction("guest" + that.$route.params.id);

        }, 100);
        $(document).ready(function () {



        });

        bus.$on("bottomIconClickEvent", (data) => {
            console.log(data);
            switch (data) {
                case "btn_add_tms":
                    this.validate();

                    break;

                case "btn_exit":
                    router.push({
                        path: "/dash"
                    });
                    break;

                default:
                    break;
            }
        });

        this.setupButtons();
    },
    data: () => ({
        showPicker: false,

    }),

    methods: {

        getUrlLoginApi: function () {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + "public/utility/checkLogin.php";

        },





        getUrlDomainSimple: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "" + nome_metodo;

        },

        fixBkc() {

            var settSmartbook = JSON.parse(window.$cookies.get("settSmartbook"));
            var settings = settSmartbook[0];
            var backgroundImage = settings.backgroundImage;
            var txt_image = this.getUrlDomainSimple("public/bck_companies/" + backgroundImage);

            var rule = ` .bkc_over_image {background-image :url(` + txt_image + `)!important}`;
            $('#addedCSS').text(rule);

        },

        getUrlDomainApi: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + "api/" + nome_metodo;

        },


        loginAction: async function (username, password, v_id) {

            //const auth = { user: this.username, password: this.password };
            // Correct username is 'foo' and password is 'bar'
            const url = this.getUrlLoginApi();


            this.success = false;
            this.error = null;

            let formData = new FormData();

            //  var pointerVue = this;
            //var pass = atob(window.$cookies.get("token_system"));
            // var pass = "password";

            // var login = username; //window.$cookies.get("username");ù

            console.log("USERNAME: ", username);
            console.log("PASSWORD: ", password);
            console.log("ID: ", v_id);

            formData.append("user", username);
            formData.append("password", password);
            formData.append("productid", "Emt");

            var pointvue = this;

            //formData.append("password", this.input.password);
            try {
                await this.axios
                    .post(url, formData, {
                        "content-type": "application/json"
                    })
                    .then(async (result) => {
                        var respo = result.data;
                        this.success = true;
                        // console.log("RESPO: ", respo   );


                        if (respo.Result == "OK") {

                            setTimeout(() => {

                                window.$cookies.set("a", respo.a, "9y");
                                window.$cookies.set("b", respo.b, "9y");
                                window.$cookies.set("token", respo.token, "9y");
                                window.$cookies.set("system", respo.system, "9y");
                                window.$cookies.set("token_system", respo.token_system, "9y");
                                window.$cookies.set("username", respo.username, "9y");
                                window.$cookies.set("user_id", respo.user_id, "9y");
                                window.$cookies.set("namesurname", respo.namesurname, "9y");
                                window.$cookies.set("is_ebadge", respo.is_ebadge, "9y");
                                window.$cookies.set("models", respo.models, "9y");
                                window.$cookies.set("product_permissions", respo.product_permissions, "9y");
                                window.$cookies.set("cid", respo.cid, "9y");
                                window.$cookies.set("is_invite_cespiti", 1, "9y");
                                window.$cookies.set("companyid_rich_hol", "", "9y");
                                window.$cookies.set("resourceid_rich_hol", "", "9y");
                                window.$cookies.set("idrichesta_rich_hol", "", "9y");
                                window.$cookies.set("sel_filter_idrichiesta_storico_ferie", "", "9y");

                            }, 1);


                            try { // verifico se è la prima volta che accede gli chiedo cambio pwd


                                setTimeout(() => {

                                    var uname = username.replace("@", "_");
                                    const args = [uname, true, ['bar', 5], {
                                        foo: 'baz'
                                    }];
                                    try {
                                        console.log("entering user");
                                        window.flutter_inappwebview.callHandler('setLoggedUser', ...args);

                                    } catch (error) {
                                        // alert(error);
                                    }

                                    pointvue.$root.$children[0].curDipTop = respo.namesurname;

                                    //update menu after login
                                    pointvue.$root.$children[0].changeMenu();
                                    //Load companies array
                                    pointvue.$root.$children[0].syncMonth();
                                    pointvue.$root.$children[0].syncGroups();

                                    pointvue.$root.$children[0].refreshMenu();

                                    pointvue.$root.$children[0].registerLogin();



                                    setTimeout(() => {
                                        router.push({
                                            path: "/editCespitiResource/" + v_id
                                        });

                                    }, 300);



                                }, 1);




                            }
                            catch (err2) {
                                this.success = false;
                                console.log(err2.message);
                                this.$swal({
                                    icon: "error",
                                    text: "Errore durante verifica password"
                                });

                                this.showSpinner = false;
                            }

                        }
                        else {


                            this.success = false;
                            this.$swal({
                                icon: "error",
                                text: respo.ErrDetails,
                                confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                                cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_emt.png' />",
                                showCancelButton: false,
                                customClass: {
                                    cancelButton: 'order-1',
                                    confirmButton: 'order-2',
                                }
                            });

                            this.showSpinner = false;



                        }













                    });
            } catch (err) {
                this.success = false;
                console.log(err.message);
                /* this.$swal({
                    icon: "error",
                    text: "Wrong credentials"
                }); */

                this.showSpinner = false;
                //  console.log(   response);

                //   this.error = err.message;
            }

        },



        getUrlDomainBase: function (nome_metodo) {
            let result = "";
            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }
            if ($(location).attr('hostname') != "localhost") {
                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";
            }
            return result + "" + nome_metodo;
        },
        setupButtons: async function () {
            setTimeout(() => {

                // this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },
        validate() {

        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },

    },
};
</script>
