<template>
    <div class="cont-table">

        <div class="spin">
            <ion-spinner name="crescent"></ion-spinner>
        </div>

        <div class="row cont_clienti_progetti_associati_risorse">
            <div class=" " style="margin:0 auto;">
                <div class="card">

                    <div class="card-body" style="padding:0px">

                        <div class="table-responsive" style="">

                            <table id="myTable" style="margin-top:57px">
                                <thead>
                                    <tr>

                                        <th class='all'>
                                            <div class="cl_checkall">
                                                <ion-checkbox name='emailgestoreall'
                                                    style="width:10px!important;height:10px!important" class='checkall'
                                                    slot='start'>
                                                </ion-checkbox>
                                            </div>
                                        </th>

                                        <th class='all'>
                                            <div class='cl_num'>N.</div>
                                        </th>
                                        <th class='all'>Cliente</th>
                                        <th class='all'>Progetto</th>
                                        <th class='desktop'>Data Inizio</th>
                                        <th class='desktop'>Data Fine</th>
                                        <th class='desktop' style="text-align: center;">Default</th>


                                    </tr>
                                </thead>

                                <tfoot>

                                    <tr>
                                        <th colspan="7">
                                        </th>
                                    </tr>
                                </tfoot>

                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="contpopupsearch" style="display:none">

            <template>
                <modal name="popupSearch" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            <v-img alt="" src="@/assets/ricerca_emt.png" max-width="25" max-height="25" />

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field v-model="textSearch" label="">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-search" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelSearch" />

                                <v-img alt="" class="btn-confirm-search" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" @click="btnConfirmSearch" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contaziende">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Aziende
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetAziende = !sheetAziende">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                        @click="manageClickAziende(item)">

                                        <v-img alt="" src="@/assets/aziende-emt.png" max-width="24" max-height="24" />

                                        <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>

                                    </v-list-item>

                                    <div style="min-height:150px;height:150px">&nbsp;</div>

                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>

        <div class="contpopupotpdelete" style="display:none">

            <template>
                <modal name="popupOtpDelete" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Codice OTP inviatoLe via mail

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field type="password" v-model="valueOtpDelete" label="" id="fldValueOtpDelete">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-otp-delete" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelOtpDelete" />

                                <v-img alt="" class="btn-confirm-otp-delete" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" @click="btnConfirmOtpDelete" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


    </div>
</template>

<script>
import apirisorse from "../utils/risorse/apirisorse";
import apiusers from "../utils/users/apiusers";
import apiotp from "../utils/otp/apiotp";


import {
    bus
} from "../main";
import router from ".././router";

export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
    },

    mounted: function () {
        /*     let self = this;
        fetch('https://jsonplaceholder.typicode.com/posts')
          .then(response => response.json())
          .then(json => self.$data.items = json) */

        //var res = apiprofile.getProfile(1,2);

        /*eslint-disable no-undef*/

        this.$root.$children[0].checkLogout();

        window.curResource = window.$cookies.get("sel_resource_clienti_assoc");

        window.curSrcText = "";

        window.myVal = "";


        window.perpage = 30;
        bus.$off("bottomIconClickEvent");



        $(document).ready(
            function () {

                $("body").off();

                window.uname = window.$cookies.get("username");
                var uname = window.uname;

                const args = [uname, true, ['bar', 5], {
                    foo: 'baz'
                }];
                try {
                    console.log("entering user");
                    window.flutter_inappwebview.callHandler('setLoggedUser', ...args);
                    console.log("passed");

                } catch (error) {
                    // alert(error);
                }

            }
        );

        $(document).ready(function () {

            $('body').on('click', '.selazienda', function () {

                console.log(("EVENTO CLICK SELAZIENDA"));

                pointerVue.sheetAziende = true;

            });


            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });


            $('body').on('click', '.checkall', function () {


                $(".val_status").prop("checked", $(this).prop("checked"));

                console.log("CHECKALL: ", $(this).prop("checked"));


                if ($(this).prop("checked")) {

                    $("tr").addClass("active-row");

                    $("tr").eq(0).removeClass("active-row");

                }

                if (!$(this).prop("checked")) {

                    $("tr").removeClass("active-row");


                }

                pointerVue.hideShowButtonsSelRow();



            });





        });

        setTimeout(() => {

            this.syncProfile();

            this.syncAziende();

        }, 100);
        // console.log (res);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);

            var ids = "";

            switch (data) {

                case "btn_delete":

                    this.deleteRow();

                    break;

                case "btn_exit":
                    router.push({
                        path: "/dash"
                    });
                    break;

                case "btn_add":
                    router.push({
                        path: "/editRisorseClientiProgetti/0"
                    });
                    break;

                case "btn_edit":



                    ids = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }
                            ids = ids + $(this).attr("data-id");
                        }

                    });

                    console.log("IDS SEL:", ids);

                    router.push({
                        path: "/editRisorseClientiProgetti/" + ids
                    });



                    break;

                case "btn_back":
                    router.push({
                        path: "/risorse"
                    });
                    break;

                default:
                    break;

            }
        });


        // this.setupButtons();

        this.setupButtonsGrid();


        window.start = 0;

        var pointerVue = this;


        pointerVue.setupGrid();

        this.hideShowButtons(false);

        setTimeout(() => {

            $("th:first").removeClass("sorting_asc");


        }, 300);

    },
    data: () => ({

        valueOtpDelete: "",


        aziende: [],

        textSearch: "",

        sheetAziende: false,
        search: '',


        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),

    computed: {

        filteredAziende() {
            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

    },

    methods: {

        getOtpDelete: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);


            window.myVal = "";


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiotp.generateOTPAndSendMail(
                v_token,
                'emt',
                'elimina_risorsa',
                ids
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from generateOTPAndSendMail", res);

                window.myVal = res.data.Result;


                setTimeout(() => {

                    $(".contpopupotpdelete").show();

                    this.$modal.show('popupOtpDelete');

                    setTimeout(() => {

                        $("#fldValueOtpDelete").focus();

                    }, 300);


                }, 200);





            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );






        },


        btnCancelOtpDelete: function () {

            this.$modal.hide('popupOtpDelete');


        },

        btnConfirmOtpDelete: function () {


            if (this.valueOtpDelete != "") {

                console.log("OTP INP: ", this.valueOtpDelete);
                console.log("ENC OTP INP: ", btoa(this.valueOtpDelete));

                if (window.myVal == btoa(this.valueOtpDelete)) {

                    this.$modal.hide('popupOtpDelete');

                    this.deleteRow();

                }
                else {

                    this.$swal({
                        icon: "error",
                        text: "Valore OTP non corretto",
                        showConfirmButton: false,
                        timer: 2000
                    });


                }



            }
            else {

                this.$swal({
                    icon: "error",
                    text: "Specificare un  valore dell'OTP",
                    showConfirmButton: false,
                    timer: 2000
                });

            }



        },



        syncAziende: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getAziende(
                v_token,
                "emt"
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getAziende", res);

                try {

                    this.aziende = res.data.filtro_aziende;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            window.curCompany = azienda.companyid;

            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

        },

        btnCancelSearch: function () {

            this.$modal.hide('popupSearch');

        },


        btnConfirmSearch: function () {

            console.log("ok");

            window.curSrcText = this.textSearch;

            this.$modal.hide('popupSearch');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

        },

        hideShowButtonsSelRow: function () {

            var tot = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;

                }

            });

            setTimeout(() => {

                var pulsantis = [];

                pulsantis.push({
                    text: "Add",
                    icon: "mdi-home-circle",
                    image: "https://app.emtool.eu/public/_lib/img/xbox-cross-emt.png",
                    link: "/settings",
                    id: "btn_add",
                    disabled: false,
                    title: "Aggiungi",
                    width: 30
                });

                if (tot == 1) {

                    pulsantis.push({
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/icon_edit_emt.png",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                        title: "Modifica",
                        width: 30
                    });




                }

                if (tot > 0) {

                    pulsantis.push({
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/icon_delete_emt.png",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                        title: "Elimina",
                        width: 30
                    });

                }

                pulsantis.push({
                    text: "Indietro",
                    icon: "mdi-arrow-left",
                    image: "https://app.emtool.eu/public/_lib/img/back-emt.png",
                    link: "/confOrariChoice",
                    id: "btn_back",
                    disabled: false,
                    title: "Indietro",
                    width: 35
                });





                this.$root.$children[0].bnavbuttons = pulsantis;



            }, 100);



        },



        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },

        setupGrid: function () {

            var pointerVue = this;

            $(document).ready(function () {

                var v_token = window.$cookies.get("token");


                window.curStatus = "S"; // in forza

                window.columns = [
                    {
                        data: 'sel_row',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '1%',
                    },

                    {
                        data: 'DT_RowIndex',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '1%',
                    },

                    {
                        data: 'fld_name',
                        orderable: false,
                        name: 'fld_name',
                        width: '4%'
                    },

                    {
                        data: 'projectname',
                        orderable: false,
                        name: 'projectname',
                        width: '4%'
                    },

                    {
                        data: 'estimatedprojectstart',
                        orderable: false,
                        name: 'estimatedprojectstart',
                        width: '4%'
                    },

                    {
                        data: 'estimatedprojectend',
                        orderable: false,
                        name: 'estimatedprojectend',
                        width: '4%'
                    },

                    {
                        data: 'is_default',
                        orderable: false,
                        name: 'is_default',
                        width: '4%'
                    },

                ];


                $(".spin").show();




                window.table = $('#myTable').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": window.perpage,

                    processing: false,
                    paging: false,
                    serverSide: false,

                    /*  pageSize: 30, */
                    "sDom": "lfrti",

                    data: window.dataset,

                    ajax: {
                        url: pointerVue.getUrlDomain("EmtRisorseClientiAssociatiApi") + '?username=' + v_token,
                        data: function (valori) {

                            console.log("VALORI:", valori);

                            try {
                                window.table.clear().draw();

                            } catch (error) {
                                console.log(error);
                            }

                            window.start = window.perpage;

                            valori.start = 0;
                            valori.length = window.perpage;

                            valori.curCompany = window.curCompany;
                            valori.curStatus = window.curStatus;

                            valori.curSrcText = window.curSrcText;

                            valori.curResource = window.curResource;


                            window.valori = valori;
                            return valori;

                        }

                    },

                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: window.columns,

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();

                        setTimeout(function () {

                            pointerVue.showSpinner = false;
                            pointerVue.$root.$children[0].showProgress = false;

                        }, 700);

                        setTimeout(() => {

                            $("th:first").removeClass("sorting_asc");


                        }, 300);


                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                        var valore = columns.hour.text + ":" + columns.minute.text;

                                        console.log(valore);

                                        $.ajax({
                                            url: pointerVue.getUrlDomain("updateHour"),
                                            data: {
                                                "id": id,
                                                "hour": valore,
                                                "v_user": "ZmFnb3N0b25p"
                                            },
                                            success: function () { }
                                        });

                                    }
                                },
                                ],
                            };
                        });

                        setTimeout(function () {

                            //   $("#myTable_filter").width($("#myTable").width());

                        }, 300);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();




                        $("#nomeRisorsaSelezionata").html(window.$cookies.get("sel_resource_name_clienti_assoc"));


                    }

                });




                //Events
                //START SETUP
                function throttle(f, delay) {
                    var timer = null;
                    return function () {
                        var context = this,
                            args = arguments;
                        clearTimeout(timer);
                        timer = window.setTimeout(function () {
                            f.apply(context, args);
                        },
                            delay || 500);
                    };
                }
                window.loading = 0;
                $("div.dataTables_filter input").unbind();
                $('div.dataTables_filter input').keyup(throttle(function () {
                    window.table.ajax.reload();

                }));
                $('#myTable_wrapper').on('scroll', function () {
                    if ($(this).scrollTop() + $(this).innerHeight() + 50 >= $(this)[0].scrollHeight) {
                        if (window.loading == 0) {
                            $(".spin").show();
                            var valori = window.valori;
                            valori.start = window.start;

                            $.ajax({
                                url: pointerVue.getUrlDomain("EmtRisorseClientiAssociatiApi") + '?username=' + v_token,
                                data: window.valori,
                                success: function (data) {
                                    $(".spin").hide();
                                    window.loading = 0;
                                    window.start += window.perpage;

                                    for (let [index, val] of data.data.entries()) {
                                        console.log(index, val);
                                        window.table.row.add(val).draw(false);
                                        //  window.dataset.push(val);
                                    }
                                    console.log(data.data);
                                },
                            });
                        }
                        window.loading = 1;
                    }
                });
                //END

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");




                var sel = `<div style="font-weight:bold;margin-left:15px">Risorsa:</div><div style="font-weight:bold;margin-left:10px" id="nomeRisorsaSelezionata"></div>`;

                $("#myTable_filter").find("label").remove();

                $("#myTable_filter").prepend(`
                <div class="cont_buttons">

                    <div class="text_title">Clienti Progetti Associati</div>
                    ` + sel + `

                </div>
                <div><img src='/emt/img/cerca-emt.png' style='border:0!important;' class='selricerca' title='Ricerca' /></div>

            `);

                $(".selricerca").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.textSearch = "";

                    $(".contpopupsearch").show();

                    pointerVue.$modal.show('popupSearch');

                });


                $('body').on('click', '.paginate_button', function () {
                    $(".spin").show();

                });

                $('body').on('click', '.refreshButton', function () {

                    $(".spin").show();
                    window.table._fnDraw();

                });


                $(".selectstatusall").on("ionChange", function (opt) {
                    console.log(opt);
                    $(".spin").show();
                    window.curStatus = this.value;



                    window.table.ajax.reload();

                });


                $('body').on('click', '.cont_clienti_progetti_associati_risorse tr', function () {

                    console.log("TR-ACTIVE");

                    setTimeout(() => {

                        var row_data = window.table.row(this).data();

                        if (row_data != null) {


                            console.log("ROW DATA: ", row_data);


                            var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            var id_sel = $(this).find(".id_row").attr("data-id");


                            var set_cls_active = 0;

                            if (last_id_sel == "undefined") {
                                set_cls_active = 1;
                            } else {
                                if (last_id_sel != id_sel) {
                                    set_cls_active = 1;
                                }
                            }

                            console.log("ROM IS ACTIVE: ", set_cls_active);



                            console.log("THIS: ", this);

                            var hasClassActive = $(this).hasClass("active-row");

                            if (hasClassActive) {

                                console.log("ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", false);

                                $(this).removeClass("active-row");


                            }

                            if (!hasClassActive) {

                                console.log("NOT ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", true);

                                $(this).addClass("active-row");



                            }

                            window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            if (window.id_par_sel == undefined) {
                                window.id_par_sel = id_sel;
                            }

                            console.log("ID SELECTED: ", window.id_par_sel);


                            console.log("FIND VAL_STATUS: ", $(this).find(".val_status"));

                            var find_check = $(this).find(".val_status");
                            if (find_check.is(":checked")) {
                                console.log("SELEZIONATO");

                            }

                            if (!find_check.is(":checked")) {
                                console.log("DESELEZIONATO");

                            }


                            pointerVue.hideShowButtonsSelRow();


                        }



                    }, 100);




                    // setTimeout(() => {

                    //     var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                    //     var id_sel = $(this).find(".id_row").attr("data-id");

                    //     $("tr").removeClass("active-row");

                    //     var set_cls_active = 0;

                    //     if (last_id_sel == "undefined") {
                    //         set_cls_active = 1;
                    //     } else {
                    //         if (last_id_sel != id_sel) {
                    //             set_cls_active = 1;
                    //         }
                    //     }

                    //     if (set_cls_active == 1) {

                    //         $(this).addClass("active-row");

                    //         window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                    //         console.log("ID: ", window.id_par_sel);

                    //         pointerVue.hideShowButtons(true);

                    //     } else {

                    //         pointerVue.hideShowButtons(false);

                    //     }

                    // }, 100);

                });

                // }

                //setup ionic calendar top
                // setTimeout(function () {



                //     $(".selectstatusall").on("ionChange", function (opt) {
                //         console.log(opt);
                //         $(".spin").show();
                //         window.curStatus = this.value;



                //         window.table.ajax.reload();

                //     });



                //     window.currDay = date;
                //     window.currDayTo = date;

                // }, 600);

                window.parentiAddedEvents = true;

            });

        },


        hideShowButtons: async function (v_enable) {

            setTimeout(() => {

                var pulsantis = [];

                if (v_enable == false) {

                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/xbox-cross-emt.png",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            title: "Aggiungi",
                            width: 30
                        },

                        {
                            text: "Indietro",
                            icon: "mdi-arrow-left",
                            image: "https://app.emtool.eu/public/_lib/img/back-emt.png",
                            link: "/confOrariChoice",
                            id: "btn_back",
                            disabled: false,
                            title: "Indietro",
                            width: 35
                        },




                    ];

                }

                if (v_enable == true) {

                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/xbox-cross-emt.png",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            title: "Aggiungi",
                            width: 30
                        },

                        {
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/icon_edit_emt.png",
                            link: "/info",
                            id: "btn_edit",
                            disabled: false,
                            title: "Modifica",
                            width: 30
                        },

                        {
                            text: "Delete",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/icon_delete_emt.png",
                            link: "/info",
                            id: "btn_delete",
                            disabled: false,
                            title: "Elimina",
                            width: 30
                        },

                        {
                            text: "Indietro",
                            icon: "mdi-arrow-left",
                            image: "https://app.emtool.eu/public/_lib/img/back-emt.png",
                            link: "/confOrariChoice",
                            id: "btn_back",
                            disabled: false,
                            title: "Indietro",
                            width: 35
                        },





                    ];

                }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },

        setupButtonsGrid: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/xbox-cross-emt.png",
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                        width: 30
                    },

                    {
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/icon_edit_emt.png",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                        width: 30
                    },

                    {
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/icon_delete_emt.png",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                        width: 30
                    },

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },

                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/qrconfirm",
                        id: "btn_qrcode",
                        image: "https://app.smart-book.it/public/smartbook/ICON_3.png",
                        disabled: false,
                    },

                    {
                        text: "Settings",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-settings-64.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_settings",
                        disabled: false,
                    },

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_info",
                        disabled: false,
                    },

                    {
                        text: "Pdf",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/sb-export-pdf-50.png",
                        class: "inverted",
                        link: "/pdf",
                        id: "btn_pdf",
                        disabled: false,
                    },

                ];

                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },

        // createPdf: async function () {

        //     var that = this;

        //     that.showSpinner = true;
        //     that.$root.$children[0].showProgress = true;

        //     // valori.currDayTo = window.currDayTo;
        //     // valori.curStatus = window.curStatus;
        //     // valori.curPiano = window.curPiano;

        //     var response = await apibookingrsa.getPdfPrenotazioni(
        //         "ZmFnb3N0b25p",
        //         3165,
        //         window.currDay,
        //         window.currDayTo,
        //         window.curStatus,
        //         window.curPiano,
        //         ""
        //     ).then((res) => {

        //         that.$root.$children[0].showProgress = false;
        //         console.log("res from getPdfPrenotazioni", res);

        //         try {

        //             console.log(res.data.Result);

        //             window.open(res.data.Result, '_blank');

        //         } catch (error) {
        //             console.log(error);
        //         }

        //     }).catch(err => {
        //             that.$root.$children[0].showProgress = false;
        //             console.log(err);
        //             var msg = err.response.data.Error;

        //             that.$swal({
        //                 icon: "error",
        //                 text: msg,
        //                 showConfirmButton: false,
        //                 timer: 8000
        //             });
        //             console.log("Errori", "Non è stato possibile esportare il pdf");
        //             console.log("response", response);

        //         }

        //     );

        // },

        deleteRow: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.$swal
                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare l'elemento selezionato ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_emt.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {

                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });

                        console.log("IDS SEL:", ids);



                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response = await apirisorse.deleteClientiProgetti(
                            ids,
                            v_token
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteClientiProgetti", res);

                            that.$swal({
                                icon: "success",
                                text: "Dati eliminati correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.dialogMsg = false;
                                that.setupButtons();

                                that.hideShowButtons(false);

                                window.table.ajax.reload();

                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },

    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
.dataTable tbody tr,
.dataTable tbody tr td {
    vertical-align: middle;
    padding: 6px !important;
}

.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #f5cf2e;

}

.pay a {
    color: #f5cf2e;

    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #f5cf2e;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}


ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.selectpianiall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    /* border-radius: 12px; */
    /* min-height: 12px; */
    /* transform: translateY(-2px); */
    background-image: url(/smartb/img/FiltroNucleo.png);
    /* padding: 1px 11px; */
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    transform: translateY(2px);
}


.cont-table .spin {

    display: flex;
    position: absolute;
    z-index: 100;
    min-width: 100vw;
    min-height: 100vh;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
}

.cont_clienti_progetti_associati_risorse #myTable {
    margin-right: 3px;
    width: 985px !important;
}

.cont_clienti_progetti_associati_risorse table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_clienti_progetti_associati_risorse table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 25px !important;
    padding-top: 5px !important;
    vertical-align: middle;
}

.cont_clienti_progetti_associati_risorse th {
    padding-left: 5px !important;
}

.cont_clienti_progetti_associati_risorse ion-datetime.dateBook {
    margin-left: 16px;
}

.cont_clienti_progetti_associati_risorse .table-responsive {
    padding: 0px;
    min-width: 90vw;
    max-width: 100vw;
}


.cont_clienti_progetti_associati_risorse #myTable_filter {
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
    background-color: white;
    border-color: #f5cf2e;

}

.cont_clienti_progetti_associati_risorse div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cl-date {
    font-size: 10px;
    color: gray;
}

.cont_clienti_progetti_associati_risorse #myTable_wrapper {
    padding-top: 0px;
    min-width: initial;
    max-width: 1000px;
    width: 1000px;

}

.cont_clienti_progetti_associati_risorse .text_title {
    background-color: white;
    color: black;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0px;
    font-size: 10px;
    min-width: 0vw
}

.cont_clienti_progetti_associati_risorse .selazienda {
    max-width: 23px;
    min-height: 23px;
    margin-left: 18px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}


.cont_clienti_progetti_associati_risorse .cl_active {
    display: inline-flex;
    align-items: center;
    min-width: 100%;
    justify-content: center;
}

.cont_clienti_progetti_associati_risorse .selectstatusall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 13px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    background-image: url(/emt/img/filtro-stato-emt.png);
    text-indent: -119px;
    background-size: 24px !important;
    background-repeat: no-repeat;
    transform: translateY(2px);
    margin-top: -5px !important;

}


.cont_clienti_progetti_associati_risorse .cont_buttons {
    display: flex !important;
    align-items: center !important;
    float: left !important;
    min-width: 95px !important;
    padding-left: 10px !important;
    justify-content: inherit !important;
}

.action-sheet-button-inner.sc-ion-action-sheet-ios {
    color: black !important;
}

.cl_tipo_contratto {
    width: 46px !important;
}

.cont_clienti_progetti_associati_risorse .cl_desc_company {
    width: 140px !important;
}

.cl_inizio_rapporto {
    width: 70px !important;
}

.cl_fine_rapporto {
    width: 70px !important;
}

.cl_totale_ferie {
    width: 60px !important;
}

.cl_costo_giornaliero {
    width: 75px !important;
}

.cl_diaria {
    width: 52px !important;
}

.cl_piano_orario {
    width: 48px !important;
}

.cl_customer_date_start {
    width: 55px !important;
}

.cl_customer_date_end {
    width: 55px !important;
}

.cont_clienti_progetti_associati_risorse .cl_checkall {
    padding-left: 1px;
    padding-top: 2px;
}

.cont_clienti_progetti_associati_risorse .checkall {
    width: 13px;
    height: 13px;
}

.cont_clienti_progetti_associati_risorse .val_status {
    width: 10px !important;
}

.cont_clienti_progetti_associati_risorse .selricerca {
    max-width: 31px;
    min-height: 31px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-right: 14px !important;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.itemAzienda {
    margin-left: 15px;
}


@media screen and (max-width: 960px) {

    .cont_clienti_progetti_associati_risorse .cl_active {
        display: contents;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
    }

    .cont_clienti_progetti_associati_risorse #myTable {
        /* margin-top:54px; */
    }

    .cont_clienti_progetti_associati_risorse #myTable_filter {
        top: 56px;
        position: fixed;

    }

}

@media screen and (max-width: 1024px) {

    .contIcon {
        display: inherit;
    }



    .cont_clienti_progetti_associati_risorse #myTable {
        max-width: 95vw !important;
        margin-right: 0px;
        min-width: 95vw !important;
        position: relative;
        width: 95vw !important;

    }

    .cont_clienti_progetti_associati_risorse #myTable_filter {
        top: 56px;
        position: fixed;

    }

    .cont_clienti_progetti_associati_risorse .table-responsive {
        padding: 0px;
        min-width: 95vw !important;
        max-width: 95vw !important;
        left: 0px;
        position: fixed;

    }

    .cont_clienti_progetti_associati_risorse #myTable_wrapper {
        min-width: initial;
        min-width: 95vw !important;
        max-width: 95vw !important;
        width: 95vw !important;
        overflow-x: hidden !important;
    }

    .cont_clienti_progetti_associati_risorse .cl_num {
        margin-left: -1px !important;
    }

    .cl_n_matricola {
        display: inline-flex;
    }

    .cl_desc_project {
        display: inline-flex;
    }

    .cl_costo_giornaliero {
        display: inline-flex;
    }

    .cl_diaria {
        display: inline-flex;
    }

    .cl_ticket {
        display: inline-flex;
    }

    .cl_piano_orario {
        display: inline-flex;
    }

    .cl_ebadge {
        display: inline-flex;
    }

    .cl_totale_ferie {
        display: inline-flex;
    }

    .cl_tipo_contratto {
        display: inline-flex;
    }

    .cl_desc_company {
        display: inline-flex;
    }

    .cl_inizio_rapporto {
        display: inline-flex;
    }

    .cl_fine_rapporto {
        display: inline-flex;
    }

    .cont_clienti_progetti_associati_risorse .cl_checkall {
        padding-left: 20px;
        padding-top: 2px;
    }



}
</style>
