import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIGroupsLogin {

  
  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  async initInsert(user)
  {

    let formData = new FormData();

    formData.append("username",  user);

    return axios.post(this.getUrlDomain("initInsertGroupLogin") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async saveGroupLogin(gruppo, v_id)
  {

    let formData = new FormData();

    formData.append("v_id",  v_id);

    for ( var key in gruppo ) {
      formData.append(key, gruppo[key]);
   }
    
   
    return axios.post(this.getUrlDomain("saveGroupLogin") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async initEdit(v_id)
  {

    let formData = new FormData();

    formData.append("v_id",  v_id);

    return axios.post(this.getUrlDomain("initEditGroupLogin") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async deleteGruppoLogin(v_id)
  {

    let formData = new FormData();

    formData.append("v_id",  v_id);

    return axios.post(this.getUrlDomain("deleteGruppoLogin") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

}

export default new APIGroupsLogin({
  url: "https://services.ebadge.it/public/api/"
})
