<template>
    <div class="cont-table">

        <div class="spin">
            <ion-spinner name="crescent"></ion-spinner>
        </div>

        <div class="row cont_azi_assoc">
            <div class=" " style="margin:0 auto;">
                <div class="card">

                    <div class="card-body" style="padding:0px">

                        <div class="table-responsive" style="">

                            <table id="myTable" style="margin-top:57px">
                                <thead>
                                    <tr>

                                        <th class='all'>
                                            <div class="cl_checkall">
                                            </div>
                                        </th>

                                        <th class='all'>
                                            <div class='cl_num'>N.</div>
                                        </th>
                                        <th class='all'>Azienda</th>

                                    </tr>
                                </thead>

                                <tfoot>

                                    <tr>
                                        <th colspan="3">
                                        </th>
                                    </tr>
                                </tfoot>

                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="contpopupsearch" style="display:none">

            <template>
                <modal name="popupSearch" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            <v-img alt="" src="@/assets/ricerca_emt.png" max-width="25" max-height="25" />

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field v-model="textSearch" label="">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-search" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelSearch" />

                                <v-img alt="" class="btn-delete-search" contain src="@/assets/icon_delete_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Elimina filtro" @click="btnResetSearch" />

                                <v-img alt="" class="btn-confirm-search" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" @click="btnConfirmSearch" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="text-left">
            <v-bottom-sheet v-model="sheetStato">
                <v-sheet class="text-center" height="340px" style="padding-bottom:120px;">
                    <v-btn class='closeStatusSheet' icon @click="sheetStato = !sheetStato">

                        <v-img alt="Proprietario Logo" style="margin-right:23px" class="  " contain
                            src="@/assets/btn_cancel3_emt.png" max-width="24" max-height="24"
                            transition="scale-transition" />

                    </v-btn>
                    <div class="py-3">
                        Assegnati
                    </div>
                    <v-list-item v-for="prj in filtroStati" :key="prj.id" @click="filterStato(prj.id)">
                        <v-list-item-title style="justify-content: center;text-align: left;">{{
                            prj.nome
                            }}</v-list-item-title>
                    </v-list-item>
                </v-sheet>
            </v-bottom-sheet>
        </div>



    </div>
</template>

<script>
//import apibookingrsa from "../utils/bookingrsa/apibookingrsa";
import apiusers from "../utils/users/apiusers";
import apiassocuserscompany from "../utils/assocuserscompany/apiassocuserscompany";

import {
    bus
} from "../main";
import router from ".././router";
import apigroupspermits from "../utils/groupspermits/apigroupspermits";
/* import $ from 'jquery'; */

export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
    },

    mounted: function () {


        /*eslint-disable no-undef*/

        this.$root.$children[0].checkLogout();

        this.$root.$children[0].colorProgress = "primary";

        window.perpage = 30;
        bus.$off("bottomIconClickEvent");

        window.curSrcText = "";


        window.curStato = "A";


        window.id_group_sel = this.$route.params.id;


        window.user_sel = window.$cookies.get("sel_utente_assoc_azi");
        window.product_sel = window.$cookies.get("sel_product_assoc_azi");

        $(document).ready(
            function () {

                $("body").off();

                window.uname = window.$cookies.get("username");
                var uname = window.uname;

                const args = [uname, true, ['bar', 5], {
                    foo: 'baz'
                }];
                try {
                    console.log("entering user");
                    window.flutter_inappwebview.callHandler('setLoggedUser', ...args);
                    console.log("passed");

                } catch (error) {
                    // alert(error);
                }

            }
        );

        $(document).ready(function () {

            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });

        });

        setTimeout(() => {
            this.syncUserSelected();
        }, 100);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_delete":

                    if (window.id_par_sel == 0) {

                        this.$swal({
                            icon: "error",
                            text: "Selezionare la riga da eliminare",
                            showConfirmButton: false,
                            timer: 2000
                        });

                    }

                    if (window.id_par_sel > 0) {

                        this.deleteRow();

                    }

                    break;

                case "btn_exit":
                    router.push({
                        path: "/dash"
                    });
                    break;

                case "btn_add":
                    router.push({
                        path: "/editUser/0"
                    });
                    break;

                case "btn_edit":

                    //alert(window.id_par_sel);

                    if (window.id_par_sel == 0) {

                        this.$swal({
                            icon: "error",
                            text: "Selezionare la riga da modifcare",
                            showConfirmButton: false,
                            timer: 2000
                        });

                    }

                    if (window.id_par_sel > 0) {

                        router.push({
                            path: "/editUser/" + window.id_par_sel
                        });

                    }

                    break;

                case "btn_back":
                    router.push({
                        path: "/groupsPermits"
                    });
                    break;

                default:
                    break;

            }
        });

        // this.setupButtons();

        this.setupButtonsGrid();

        //wait loading
        /* var v_token = window.$cookies.get("token");
        var v_company = window.$cookies.get("sel_filter_azienda_utenti"); */
        window.start = 0;
        //console.log("DOMAIN: " + pointerVue.getUrlDomain("getListingRSA"));

        // console.log("HOSTNAME: " + $(location).attr('hostname'));
        // console.log("PROTOCOL: " + $(location).attr('protocol'));
        var pointerVue = this;

        /*         $.ajax({
                    url: pointerVue.getUrlDomain("getUtenti") + '?username=' + v_token + "&company=" + v_company,
                    data: {

                        currDay: window.currDay,
                        currDayTo: window.currDayTo,
                        curStatus: window.curStatus,
                        curPiano: window.curPiano,
                        start: window.start,
                        length: 30,

                    },
                    success: function (dati) {

                        window.dataset = dati.data;
                        window.loading = 0;
                        window.start = +30;
                        pointerVue.setupGrid();

                    }
                }); */

        /*  window.loading = 0;
         window.start = +30; */
        pointerVue.setupGrid();

        this.hideShowButtons(false);

    },
    data: () => ({


        sheetStato: false,


        filtroStati: [{
            id: "",
            nome: "Tutti"
        }, {
            id: "A",
            nome: "Assegnati"
        }, {
            id: "N",
            nome: "Non Assegnati"
        }],



        textSearch: "",

        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),

    methods: {

        btnResetSearch: function () {

            window.curSrcText = "";



            this.$modal.hide('popupSearch');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();
        },



        filterStato: function (stato) {


            console.log("STATO SEL: ", stato);

            this.sheetStato = false;

            window.curStato = stato;



            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

        },



        btnCancelSearch: function () {

            this.$modal.hide('popupSearch');

        },


        btnConfirmSearch: function () {

            console.log("ok");

            window.curSrcText = this.textSearch;

            window.curStato = "";

            this.$modal.hide('popupSearch');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

        },

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },


        setupGrid: function () {

            // var that = this;
            //  var pathLocal = that.$withBase('/');

            var pointerVue = this;

            //var dateTest = new Date();
            //dateTest = dateTest.setDate(dateTest.getDate() + 365);
            //const dateTest2 = new Date(dateTest).toJSON().slice(0, 10);

            //console.log("DATE: " + dateTest2) //2015-07-23

            var filtri = `<img src='/emt/img/filtro-stato-emt.png' style='border:0!important;' class='selassegnati' title='Assegnati' />`;

            const date = new Date().toJSON().slice(0, 10)

            //console.log("DATE: " + date) //2015-07-23

            //  var $futureTime = dateTest2;

            $(document).ready(function () {

                var v_token = window.$cookies.get("token");
                //   var v_user_sel = window.$cookies.get("sel_utente_assoc_azi");

                window.columns = [

                    {
                        data: 'sel_row',
                        orderable: false,
                        name: 'sel_row',
                        width: '1%'
                    },

                    {
                        data: 'DT_RowIndex',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '1%',
                    },

                    {
                        data: 'fld_name',
                        orderable: false,
                        name: 'fld_name',
                        width: '10%'
                    },


                ];



                window.table = $('#myTable').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": window.perpage,

                    processing: false,
                    paging: false,
                    serverSide: false,

                    /*  pageSize: 30, */
                    "sDom": "lfrti",

                    data: window.dataset,

                    ajax: {
                        url: pointerVue.getUrlDomain("aziendeGruppo") + '?username=' + v_token,
                        data: function (valori) {

                            console.log("VALORI:", valori);

                            try {
                                window.table.clear().draw();

                            } catch (error) {
                                console.log(error);
                            }

                            window.start = window.perpage;

                            valori.start = 0;
                            valori.length = window.perpage;
                            valori.currDay = window.currDay;
                            valori.currDayTo = window.currDayTo;
                            valori.curStatus = window.curStatus;
                            valori.curPiano = window.curPiano;

                            valori.curSrcText = window.curSrcText;

                            valori.user_sel = window.user_sel;
                            valori.product_sel = window.product_sel;

                            valori.curStato = window.curStato;

                            valori.id_group_sel = window.id_group_sel;

                            window.valori = valori;
                            return valori;

                        }

                    },


                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: window.columns,

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();

                        setTimeout(function () {

                            pointerVue.showSpinner = false;
                            pointerVue.$root.$children[0].showProgress = false;

                        }, 700);


                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                        var valore = columns.hour.text + ":" + columns.minute.text;

                                        console.log(valore);

                                        $.ajax({
                                            url: pointerVue.getUrlDomain("updateHour"),
                                            data: {
                                                "id": id,
                                                "hour": valore,
                                                "v_user": "ZmFnb3N0b25p"
                                            },
                                            success: function () { }
                                        });

                                    }
                                },
                                ],
                            };
                        });

                        setTimeout(function () {

                            //   $("#myTable_filter").width($("#myTable").width());

                        }, 300);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();

                        // $('.p_valore').number(true, 1, ',', '.');
                        // $('.p_totale').number(true, 2, ',', '.');
                    }

                });




                //Events
                //START SETUP
                function throttle(f, delay) {
                    var timer = null;
                    return function () {
                        var context = this,
                            args = arguments;
                        clearTimeout(timer);
                        timer = window.setTimeout(function () {
                            f.apply(context, args);
                        },
                            delay || 500);
                    };
                }
                window.loading = 0;
                $("div.dataTables_filter input").unbind();
                $('div.dataTables_filter input').keyup(throttle(function () {
                    window.table.ajax.reload();

                }));
                $('#myTable_wrapper').on('scroll', function () {
                    if ($(this).scrollTop() + $(this).innerHeight() + 50 >= $(this)[0].scrollHeight) {
                        if (window.loading == 0) {
                            $(".spin").show();

                            setTimeout(() => {

                                $(".spin").hide();

                            }, 500);

                            var valori = window.valori;
                            valori.start = window.start;

                            $.ajax({
                                url: pointerVue.getUrlDomain("aziendeGruppo") + '?username=' + v_token,
                                data: window.valori,
                                success: function (data) {
                                    //  $(".spin").hide();
                                    window.loading = 0;
                                    window.start += window.perpage;

                                    for (let [index, val] of data.data.entries()) {
                                        console.log(index, val);
                                        window.table.row.add(val).draw(false);
                                        //  window.dataset.push(val);
                                    }
                                    console.log(data.data);
                                },
                            });
                        }
                        window.loading = 1;
                    }
                });
                //END

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");


                $("#myTable_filter").find("label").remove();

                $("#myTable_filter").prepend(`
                <div class="cont_buttons">


                    <div class="cont_title">

                        <div class="text_title">Aziende Gruppo</div>

                        ` + filtri + `

                        <div class="cl_sec_utente">
                        
                            <div class="cl_nome_utente" id='nome_utente'></div>

                            <div class="cl_nome_prodotto" id='nome_prodotto'></div>

                        </div>    

                    
                    </div>


                </div>
                <div><img src='/emt/img/cerca-emt.png' style='border:0!important;' class='selricerca' title='Ricerca' /></div>

            `);


                $('body').on('click', '.selassegnati', function () {

                    console.log(("EVENTO CLICK SELSTATO"));

                    pointerVue.sheetStato = true;

                });


                $(".selricerca").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.textSearch = "";

                    $(".contpopupsearch").show();

                    pointerVue.$modal.show('popupSearch');

                });

                $('body').on('click', '.paginate_button', function () {
                    $(".spin").show();

                });

                $('body').on('click', '.refreshButton', function () {

                    $(".spin").show();
                    window.table._fnDraw();

                });


                $('body').on('click', '.checkall', function () {


                    $(".val_status").prop("checked", $(this).prop("checked"));

                    var is_select = 0;
                    if ($(this).prop("checked")) {
                        is_select = 1;
                    }

                    console.log("IS_SELECT: ", is_select);

                    var ids = "";
                    $(".val_status").each(function (index) {

                        console.log(index);

                        if (ids != "") {
                            ids = ids + ",";
                        }
                        ids = ids + $(this).attr("data-id");

                    });

                    console.log("ID REC: ", ids);



                    //pointerVue.updAssocAziAll(is_select);

                });


                $('body').on('click', '.val_status', function () {

                    // var is_select = 0;

                    // var chk = $(this).attr("aria-checked");

                    // if (chk == "false") {
                    //     is_select = 1;
                    // }

                    // var id_guest = $(this).val();

                    // console.log("IS_SELECT: ", is_select);
                    // console.log("ID_AZI: ", id_guest);

                    // pointerVue.updAssocAzi(is_select, id_guest);

                    var ids = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }
                            ids = ids + $(this).attr("data-id");
                        }

                    });


                    console.log("IDS: ", ids);
                    console.log("ID: ", window.id_group_sel);
                    

                    pointerVue.updAssocAzi(ids, window.id_group_sel);

                });




                $('body').on('click', '.cont_azi_assoc tr', function () {

                    setTimeout(() => {

                        var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                        var id_sel = $(this).find(".id_row").attr("data-id");

                        $("tr").removeClass("active-row");

                        var set_cls_active = 0;

                        if (last_id_sel == "undefined") {
                            set_cls_active = 1;
                        } else {
                            if (last_id_sel != id_sel) {
                                set_cls_active = 1;
                            }
                        }

                        if (set_cls_active == 1) {

                            $(this).addClass("active-row");

                            window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            console.log("ID: ", window.id_par_sel);

                            pointerVue.hideShowButtons(true);

                        } else {

                            pointerVue.hideShowButtons(false);

                        }

                    }, 100);

                });

                // }

                //setup ionic calendar top
                setTimeout(function () {

                    $("body").on("ionChange", ".dateBook", function (opt) {

                        console.log(opt);
                        var date = this.value;
                        //var dateMysql = date.toJSON().slice(0, 19).replace('T', ' ')   ;
                        dateMysql = date.substr(0, 10);

                        console.log(dateMysql);

                        $.ajax({
                            url: "{{ route('smartbook.updateDate') }}",
                            data: {
                                "id": $(this).data("id"),
                                "date": dateMysql
                            },
                        });

                    });

                    $(".selectstatusall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curStatus = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    $(".selectpianiall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curPiano = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    window.currDay = date;
                    window.currDayTo = date;

                }, 600);

                window.parentiAddedEvents = true;

            });

        },

        updAssocAziAll: async function (chk_sel) {

            var v_token = window.$cookies.get("token");
            var v_user_sel = window.$cookies.get("sel_utente_assoc_azi");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiassocuserscompany.updateOwnerAllAzienda(
                chk_sel,
                v_token,
                v_user_sel,
                window.product_sel
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from updateOwnerAllAzienda", res);

                window.table.ajax.reload();


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },



        updAssocAzi: async function (id_azi_sel, v_id_grp) {

          

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apigroupspermits.updateAzienda(
                id_azi_sel,
                v_id_grp
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from updateAzienda", res);


                if (window.curStato != "") {

                    window.table.ajax.reload();

                }


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },




        hideShowButtons: async function (v_enable) {

            setTimeout(() => {

                var pulsantis = [];

                if (v_enable == false) {

                    pulsantis = [





                        {
                            text: "Indietro",
                            icon: "mdi-arrow-left",
                            image: "https://app.emtool.eu/public/_lib/img/back-emt.png",
                            link: "/tipologiapagamento",
                            id: "btn_back",
                            disabled: false,
                            title: "Indietro",
                            width: 35
                        },


                    ];

                }

                if (v_enable == true) {

                    pulsantis = [





                        {
                            text: "Indietro",
                            icon: "mdi-arrow-left",
                            image: "https://app.emtool.eu/public/_lib/img/back-emt.png",
                            link: "/tipologiapagamento",
                            id: "btn_back",
                            disabled: false,
                            title: "Indietro",
                            width: 35
                        },

                    ];

                }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },

        setupButtonsGrid: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/xbox-cross-emt.png",
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                        width: 30
                    },

                    {
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/icon_edit_emt.png",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                        width: 30
                    },

                    {
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/icon_delete_emt.png",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                        width: 30
                    },

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },

                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/qrconfirm",
                        id: "btn_qrcode",
                        image: "https://app.smart-book.it/public/smartbook/ICON_3.png",
                        disabled: false,
                    },

                    {
                        text: "Settings",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-settings-64.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_settings",
                        disabled: false,
                    },

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_info",
                        disabled: false,
                    },

                    {
                        text: "Pdf",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/sb-export-pdf-50.png",
                        class: "inverted",
                        link: "/pdf",
                        id: "btn_pdf",
                        disabled: false,
                    },

                ];

                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },


        syncUserSelected: async function () {


            var v_token = window.$cookies.get("token");
            var v_user_sel = window.$cookies.get("sel_utente_assoc_azi");

            var that = this;


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiassocuserscompany.getOwnerSelezionato(
                v_token,
                v_user_sel
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getOwnerSelezionato", res);

                $("#nome_utente").html(res.data.owner);

                var v_nome_prodotto = window.product_sel.charAt(0).toUpperCase() + window.product_sel.slice(1);

                if (v_nome_prodotto == "Invoice") {
                    v_nome_prodotto = "Invoiceweb";
                }

                $("#nome_prodotto").html(v_nome_prodotto);



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


            //$("#nome_utente").html("Utente Prova");

        },




        syncUserSelectedOld: async function () {


            var v_token = window.$cookies.get("token");
            var v_user_sel = window.$cookies.get("sel_utente_assoc_azi");

            var that = this;


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiassocuserscompany.getUtenteSelezionato(
                v_token,
                v_user_sel
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getUtenteSelezionato", res);

                $("#nome_utente").html(res.data.utente.name);

                var v_nome_prodotto = window.product_sel.charAt(0).toUpperCase() + window.product_sel.slice(1);

                if (v_nome_prodotto == "Invoice") {
                    v_nome_prodotto = "Invoiceweb";
                }

                $("#nome_prodotto").html(v_nome_prodotto);



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


            //$("#nome_utente").html("Utente Prova");

        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },


        deleteRow: async function () {

            var that = this;

            that.$swal
                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare l'elemento selezionato ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Ok",
                })
                .then(async function (result) {

                    if (result.value == true) {

                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response = await apiusers.deleteUtente(
                            window.id_par_sel
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteUtente", res);

                            that.$swal({
                                icon: "success",
                                text: "Dati eliminati correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.dialogMsg = false;
                                that.setupButtons();

                                // window.table._fnDraw();
                                // that.hideShowButtons(false);

                                window.table.ajax.reload();

                                that.hideShowButtons(false);


                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },

    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
.cl_nome_prodotto {
    margin-left: 30px;
}


.cont_azi_assoc .selassegnati {
    max-width: 23px;
    min-height: 23px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 23px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}


.cont_azi_assoc .cont_buttons {
    display: flex;
    align-items: center;
    float: left;
    min-width: 142px;
    padding-left: 10px;
    justify-content: inherit;
}

.dataTable tbody tr,
.dataTable tbody tr td {
    vertical-align: middle;
    padding: 6px !important;
}

.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #f5cf2e;

}

.pay a {
    color: #f5cf2e;

    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #f5cf2e;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}

.table-responsive {
    max-width: 100vw;
}

ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.selectpianiall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    /* border-radius: 12px; */
    /* min-height: 12px; */
    /* transform: translateY(-2px); */
    background-image: url(/smartb/img/FiltroNucleo.png);
    /* padding: 1px 11px; */
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    transform: translateY(2px);
}


.cont-table .spin {

    display: flex;
    position: absolute;
    z-index: 100;
    min-width: 100vw;
    min-height: 100vh;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
}

.cont_azi_assoc #myTable {
    margin-right: 3px;
}

.cont_azi_assoc table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_azi_assoc table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 25px !important;
}

.cont_azi_assoc th {
    padding-left: 5px !important;
}

.cont_azi_assoc ion-datetime.dateBook {
    margin-left: 16px;
}

.cont_azi_assoc .table-responsive {
    padding: 0px;
    min-width: 80vw;
    max-width: 96vw;
}


.cont_azi_assoc #myTable_filter {
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
    background-color: white;
    border-color: #f5cf2e;
}

.cont_azi_assoc div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cl-date {
    font-size: 10px;
    color: gray;
}

.cont_azi_assoc #myTable_wrapper {
    padding-top: 0px;
}

.cont_azi_assoc .text_title {
    background-color: white;
    color: black;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0px;
    font-size: 10px;
    min-width: 4vw
}

/* .cont_azi_assoc .cl_active {
    display:inline-flex;
    align-items:center;
    justify-content:flex-start!important;
    text-align:center!important
} */

.cont_azi_assoc .cl_active {
    display: inline-flex;
    align-items: center;
    /* justify-content: flex-start!important; */
    /*  text-align: center; */
    min-width: 100%;

    justify-content: center;
}

.cont_azi_assoc .selricerca {
    max-width: 31px;
    min-height: 31px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-right: 14px !important;
}


.cont_azi_assoc .cl_checkall {
    padding-left: 1px;
    padding-top: 2px;
}


.cont_azi_assoc .checkall {
    width: 13px;
    height: 13px;
}


.cont_azi_assoc .val_status {
    width: 10px;
    height: 10px;
}



.cont_title {
    display: inline-flex;
}

.cl_utente_sel {
    padding-top: 5px;
}

.cl_nome_utente {
    padding-top: 1px;
    margin-left: 10px
}

.cl_sec_utente {
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
}


@media screen and (max-width: 960px) {


    .cont_azi_assoc .cl_checkall {
        /* margin-left: -19px; */
        /* text-align: center!important; */
    }

    .cl_nome_utente {
        padding-top: 5px;
        margin-left: 0px !important;
    }




    .cont_azi_assoc .cl_active {
        display: contents;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
    }

    .cont_azi_assoc #myTable {
        /* margin-top:54px; */
    }

    .cont_azi_assoc #myTable_filter {
        top: 56px;
        position: fixed;

    }

}

@media screen and (max-width: 1024px) {

    .contIcon {
        display: inherit;
    }


    .cont_title {
        display: table;
    }

    .cont_azi_assoc #myTable {
        max-width: 95vw !important;
        margin-right: 0px;
        min-width: 95vw !important;
        position: relative;
        width: 95vw !important;

    }

    .cont_azi_assoc #myTable_filter {
        top: 56px;
        position: fixed;

    }

    .cont_azi_assoc .table-responsive {
        padding: 0px;
        min-width: 95vw !important;
        max-width: 95vw !important;
        left: 0px;
        position: fixed;

    }

    .cont_azi_assoc #myTable_wrapper {
        min-width: initial;
        min-width: 95vw !important;
        max-width: 95vw !important;
        width: 95vw !important;
        overflow-x: hidden !important;
    }

    .cont_azi_assoc .cl_num {
        margin-left: -3px !important;
    }





}
</style>
