import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APICespiti {

  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  async initInsert(user, id_cmp) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id_cmp", id_cmp);

    return axios.post(this.getUrlDomain("EmtInitInsertCespitiAdminApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async insertCespiti(cespiti, user, file) {

    let formData = new FormData();


    formData.append("username", user);

    if (file && (file != undefined)) {

      formData.append("attachment", file[0]);

    }

    for (var key in cespiti) {
      formData.append(key, cespiti[key]);
    }


    return axios.post(this.getUrlDomain("EmtInsertCespitiAdminApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getOfficeRes(user, id_res) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("v_resource_id", id_res);

    return axios.post(this.getUrlDomain("EmtCespitiGetOfficeResAdminApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async initEdit(id_asset, user, id_cmp) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id_asset);
    formData.append("id_cmp", id_cmp);

    return axios.post(this.getUrlDomain("EmtInitEditCespitiAdminApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async updateCespiti(cespiti, user, file, attach_remove, id) {

    let formData = new FormData();


    formData.append("username", user);
    formData.append("attach_remove", attach_remove);
    formData.append("id", id);

    if (file && (file != undefined)) {

      formData.append("attachment", file[0]);

    }

    for (var key in cespiti) {
      formData.append(key, cespiti[key]);
    }


    return axios.post(this.getUrlDomain("EmtUpdateCespitiAdminApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  

  async updateCespitiNonAssegnato(cespiti, user, file, attach_remove, id) {

    let formData = new FormData();


    formData.append("username", user);
    formData.append("attach_remove", attach_remove);
    formData.append("id", id);

    if (file && (file != undefined)) {

      formData.append("attachment", file[0]);

    }

    for (var key in cespiti) {
      formData.append(key, cespiti[key]);
    }


    return axios.post(this.getUrlDomain("EmtUpdateCespitiNonAssegnatoAdminApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async getRisorseCespiti(companyid) {

    let formData = new FormData();


    formData.append("companyid", companyid);


    return axios.post(this.getUrlDomain("getRisorseCespiti"), formData, { 'Content-Type': 'multipart/form-data' });

  }





  async deleteCespiti(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id);

    return axios.post(this.getUrlDomain("EmtDeleteCespitiAdminApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async mandaInvito(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id);

    return axios.post(this.getUrlDomain("EmtSendInviteCespitiApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async downAttach(id) {

    let formData = new FormData();

    formData.append("v_id", id);

    return axios.post(this.getUrlDomain("EmtDownAllegatoCespitiApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async insertCespitiNonAssegnato(cespiti, user, file) {

    let formData = new FormData();


    formData.append("username", user);

    if (file && (file != undefined)) {

      formData.append("attachment", file[0]);

    }

    for (var key in cespiti) {
      formData.append(key, cespiti[key]);
    }


    return axios.post(this.getUrlDomain("EmtInsertCespitiNonAssegnatoAdminApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }




  async associaRisorsa(id_risorsa, v_id, v_data) {

    let formData = new FormData();


    formData.append("id_risorsa", id_risorsa);
    formData.append("v_id", v_id);
    formData.append("v_data", v_data);


    return axios.post(this.getUrlDomain("associaRisorsa"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async mandaInvitoMaterialeAssegnato(id, user, body_mail) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id);
    formData.append("body_mail", body_mail);

    return axios.post(this.getUrlDomain("EmtSendInviteMatAssignCespitiApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async mandaInvitoMaterialeNonAssegnato(id, user, body_mail, id_materiale) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id);
    formData.append("body_mail", body_mail);
    formData.append("id_materiale", id_materiale);

    return axios.post(this.getUrlDomain("EmtSendInviteMatNotAssignCespitiApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async insertCespitiNonAssegnatoByResource(cespiti, user, file, id_res) {

    let formData = new FormData();


    formData.append("username", user);
    formData.append("v_id_res", id_res);

    if (file && (file != undefined)) {

      formData.append("attachment", file[0]);

    }

    for (var key in cespiti) {
      formData.append(key, cespiti[key]);
    }


    return axios.post(this.getUrlDomain("EmtInsertCespitiNonAssegnatoByResApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async disassociaRisorse(v_id, user, data_riconsegna) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("v_id", v_id);
    formData.append("data_riconsegna", data_riconsegna);

    return axios.post(this.getUrlDomain("disassociaRisorse"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getTipologie(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("getTipologieCespiti"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async saveSignatureCespiti(v_id, v_img_firma, v_user) {

    let formData = new FormData();

    formData.append("v_id", v_id);
    formData.append("v_img_firma", v_img_firma);
    formData.append("v_user", v_user);

    return axios.post(this.getUrlDomain("saveSignatureCespiti"), formData, { 'Content-Type': 'multipart/form-data' });

  }






}

export default new APICespiti({
  url: "https://services.ebadge.it/public/api/"
})
