var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"w-container v-overflow-conf-login confInvioMailEmt",staticStyle:{"padding-top":"59px","background-color":"rgb(255 255 255 / 50%)"}},[_c('v-row',[_c('v-col',{staticClass:"mainCont",staticStyle:{"min-width":"100vw","position":"fixed","left":"0","padding-left":"0px"}},[_c('div',{staticClass:"mainTitle",staticStyle:{"background-color":"white!important","color":"black","font-weight":"bold","padding":"4px","font-size":"10px","min-width":"100vw","border-color":"#f5cf2e"}},[_vm._v(_vm._s(_vm.titolo))])])],1),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('label',{staticStyle:{"font-size":"11px"}},[_vm._v("Numero Massimo Tentativi Accesso Errati")]),_c('div',{staticClass:"v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted",staticStyle:{"cursor":"pointer"},attrs:{"id":"d_fido_ant_popup"}},[_c('div',{staticClass:"v-input__control"},[_c('div',{staticClass:"v-input__slot"},[_c('div',{staticClass:"v-text-field__slot"},[_c('label',{staticClass:"v-label v-label--active theme--light",staticStyle:{"left":"0px","right":"auto","position":"absolute"},attrs:{"for":"input-29"}}),_c('vue-autonumeric',{ref:"fldDaysLeft",attrs:{"options":{
                                    digitGroupSeparator: '',
                                    decimalCharacter: ',',
                                    decimalCharacterAlternative: '',
                                    currencySymbol: '',
                                    currencySymbolPlacement: 's',
                                    roundingMethod: 'U',
                                    minimumValue: '0',
                                    decimalPlaces: '0'
                                },"id":"fldDaysLeft"},model:{value:(_vm.configuration.num_max_err),callback:function ($$v) {_vm.$set(_vm.configuration, "num_max_err", $$v)},expression:"configuration.num_max_err"}})],1)]),_c('div',{staticClass:"v-text-field__details"},[_c('div',{staticClass:"v-messages theme--light"},[_c('div',{staticClass:"v-messages__wrapper"})])])])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }