import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIGroupsPermits {

  
  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  async saveGroup(gruppo, user, v_id)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("v_id",  v_id);

    for ( var key in gruppo ) {
      formData.append(key, gruppo[key]);
   }
    
   
    return axios.post(this.getUrlDomain("saveGroup") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async editGroup(v_id, user)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("v_id",  v_id);

    return axios.post(this.getUrlDomain("editGroup") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async deleteGruppo(v_id, user)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("v_id",  v_id);

    return axios.post(this.getUrlDomain("deleteGruppo") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }




  async updateAzienda(id_azi_sel, v_id_grp)
  {

    let formData = new FormData();

    formData.append("id_azi_sel",  id_azi_sel);
    formData.append("v_id_grp",  v_id_grp);

    return axios.post(this.getUrlDomain("updateAziendaGruppo") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }




}

export default new APIGroupsPermits({
  url: "https://services.ebadge.it/public/api/"
})
